.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::placeholder {
  opacity: 1 !important;
}

.rowTable .rowTableCell {
  padding: 5px !important;
  color: #000000;
  font-size: 16px;
}

.SAVED {
  background-color: #27ae60;
}
.APPROVED {
  background-color: #27ae60;
}
.FAILED {
  background-color: #ff3535;
}
.VOIDED {
  background-color: #a9a9a9;
}
.DECLINED {
  background-color: #eb5757;
}
.REFUND {
  background-color: #eb5757;
}
.FAIL {
  background-color: #ff3535;
}
.BATCHED {
  background-color: #ffa500;
}
.PENDING {
  background-color: #ffa500;
}
.ISSUE {
  background-color: #ffa500;
}
.ISSUED {
  background-color: #ffa500;
}
.Expired {
  background-color: #a9a9a9;
}
.Active {
  background-color: #27ae60;
}
.Pending {
  background-color: #eb5757;
}

.PaymentInfoTitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #543379;
  text-transform: uppercase;
}
.Title {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.12px;
  text-align: left;
  color: #454d58;
}
.numberPaymentMethod {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Last_4_digit {
  border-radius: 4px;
  border: solid 1px #c6cacc;
  float: right;
  margin-bottom: 4px;
  margin-top: -49px;
  background-color: #f1f1f1;
}
.td_style1 {
  width: 149px;
  height: 32px;
  margin: 0 2px 0 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  text-align: left;
  color: #787885;
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.td_style {
  width: 191px;
  height: 32px;
  margin: 0 2px 0 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #19191d;
}
.newblueActionBtn {
  font-family: Oswald !important;
  text-transform: capitalize;
  border-radius: 30px;
  border: solid 2px #41b5c2 !important;
  border: 1px solid;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: #41b5c2;
  padding: 5px 22px;
  cursor: pointer;
}
.Title {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.12px;
  text-align: left;
  color: #454d58;
}
.itemWrap {
  margin-bottom: 10px !important;
}
.Expense-Number221 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
}
.Expense-Number211 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.88px;
  text-align: left;
  color: #454d58;
  overflow: hidden;
  white-space: break-spaces;
  word-break: break-all;
}
.webDisplay {
  display: block !important;
}
.mobileDisplay {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .webDisplay {
    display: none !important;
  }
  .mobileDisplay {
    display: block !important;
  }
}

.css-ll1irk-MuiPaper-root-MuiAccordion-root {
  display: contents !important;
}

.ChangPayModal + #menu-expiryMonth {
  z-index: 3000 !important;
}
.ChangPayModal + #menu-expiryYear {
  z-index: 3000 !important;
}
.ChangPayModal + #menu-AccountType {
  z-index: 3000 !important;
}

.tabBtn .MuiTab-wrapper {
  display: inline;
}
.raidoBtn {
  margin: 0 10px 0 0 !important;
}

.ButtonBG {
  width: 150px !important;
  height: 40px !important;
  margin: 0 20px 0 0 !important;
  padding: 0 0.2px 0 0 !important;
  border-radius: 30px !important;
  border: solid 2px #41b5c2 !important;
  background-color: #41b5c2 !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 1.44px !important;
  line-height: 1 !important;
  letter-spacing: 1.44px !important;
  text-align: center !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-family: Oswald !important;
}
.ButtonBG_Disable {
  width: 150px !important;
  height: 40px !important;
  margin: 0 20px 0 0 !important;
  padding: 0 0.2px 0 0 !important;
  border-radius: 30px !important;
  border: solid 2px #94d2da !important;
  background-color: #94d2da !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1 !important;
  letter-spacing: 1.44px !important;
  text-align: center !important;
  color: #ffffff !important;
  font-size: 18px !important;
  font-family: Oswald !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 68% !important;
}
/* .css-uhb5lp {
  max-width: 53% !important;
} */
.NETWELL .tabBtn {
  background-color: #f4f4f4 !important;
}
.NETWELL .tabBtn {
  background-color: #f4f4f4 !important;
}
.UHF .tabBtn {
  background-color: #eae8da !important;
}
.UHF .tabBtn {
  background-color: #eae8da !important;
}
.dismissable-info .dismiss {
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 18px;
  color: #f2f2f2;
  cursor: pointer;
}
.chatbody .intentbubble {
  display: inline-block;
  padding: 2px 0px 2px 9px;
  margin-bottom: 5px;
  border-radius: 9px;
  width: "fit-content" !important;
  max-width: 195px !important;
  min-width: 160px !important;
}
.chatbody .intentbubble p {
  margin-bottom: 0rem !important;
  font-size: 15px;
  text-align: left;
  margin-top: 0px !important;
  line-height: 1.4;
  word-break: break-word;
  font-family: "Roboto";
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.chatbody .bubble p {
  margin-bottom: 0rem !important;
  font-size: 16px;
  margin-top: 0 !important;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
  font-family: "Roboto";
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.chatbody .incoming {
  text-align: left;
  display: "flex";
}
.chatbody .incoming .bubble {
  color: #333333;
  background-color: rgb(255, 254, 254);
}
.chatbody .incoming .bubble .component {
  background-color: none;
}
.chatbody .outgoing {
  text-align: right;
}
.chatbody .outgoing .bubble {
  margin-right: 10px !important;
  color: #fdfdff;
  background-color: #4f4f4f;
}
.intent {
  background-color: "#54337A" !important;
  color: #fff;
  margin-right: 5px;
  cursor: "pointer";
}
.spinner {
  width: 70px;
  text-align: left;
}
.chatbody .bubble {
  display: inline-block;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 9px;
  max-width: 100%;
}
.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.chatbodyMobile .bubble {
  display: inline-block;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 9px;
  max-width: 330px;
}
.chatbodyMobile .intentbubble {
  display: inline-block;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 9px;
  min-width: fit-content;
}
.chatbodyMobile .intentbubble p {
  margin-bottom: 0rem !important;
  font-size: 18px;
  margin: 0 !important;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
  font-family: "Roboto";
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.scrolling-wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.chatbodyMobile .bubble p {
  margin-bottom: 0rem !important;
  font-size: 18px;
  text-align: left;
  line-height: 1.4;
  word-break: break-word;
  font-family: "Roboto";
  flex-grow: 0;
  margin: 0 !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.chatbodyMobile .incoming {
  text-align: left;
}
.chatbodyMobile .incoming .bubble {
  color: #333333 !important;
  background-color: rgba(223, 223, 223, 0.5) !important;
}
.chatbodyMobile .outgoing {
  text-align: right;
}
.chatbodyMobile .outgoing .bubble {
  color: #fdfdff;
  background-color: #4f4f4f;
}
.searchfont {
  line-height: 1.4;
  word-break: break-word;
  font-family: "Roboto";
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.inputBoxChat:hover {
  background-color: #fff;
  border-color: #000 !important;
}
.InputAISearch:hover {
  background-color: #fff;
  border-color: #000 !important;
}
